<template>
  <div class="keyboard">

    <!--省份简写键盘-->
    <div class="td2 td_nor" v-if="!specialBtn" v-for="(itemName,idx) in keyboardValue" @click="tapKeyboard(itemName,idx)">
      {{itemName}}
    </div>

    <!--数字键盘不可点击-->
    <div v-if="specialBtn && !tapNum" class="td td_num board_bg" v-for="(itemName,idx) in keyboardNumber">
      {{itemName}}
    </div>

    <!--数字键盘可点击-->
    <div v-if="specialBtn && tapNum" class="td td_num" v-for="(itemName,idx) in keyboardNumber" @click="tapKeyboard(itemName,idx)" >
      {{itemName}}
    </div>

    <!--字母键盘-->
    <div v-if="specialBtn" v-bind:class="(idx === 8 ? 'study' : '') + (idx === 9 ? 'police' : '')" class="td"
          v-for="(itemName,idx) in keyboardAlph">
      <div class="del_icon" v-if="idx === 19">
        <img src="https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/techManage/deleteByKeyBord.png"
               v-if="idx === 19" class="del_icon" @click="tapKeyboard(itemName,idx)" />
      </div>
      <div v-else-if="idx === 8" @click="plateNo.length === 1 ? '' : tapKeyboard(itemName,idx)">
        {{itemName}}
      </div>
      <div v-else-if="idx === 9" @click="plateNo.length === 1 ? '' : tapKeyboard(itemName,idx)">
        {{itemName}}
      </div>
      <div v-else-if="idx !== 19" @click="tapKeyboard(itemName,idx)">
        {{itemName}}
      </div>
    </div>

    <!-- <div class="tm " v-if="!specialBtn"></div>
    <div class="tm " v-if="!specialBtn"></div>
    <div class="tm " style="padding-right:5px" v-if="!specialBtn"></div> -->
    <!--完成按钮 start-->
    <!-- <div class="td td_spec_fin" v-for="(itemName,idx) in keyboard2For" @click="tapSpecBtn(itemName,idx)">
      {{itemName}}
    </div> -->
    <!--完成按钮 end-->
  </div>

</template>

<script>
export default {
  name: "plateNoInput",
  data () {
    return {
      showFlg : true,
      keyboardNumber: '1234567890',
      keyboardAlph: 'QWERTYUP学警ASDFGHJKL巛ZXCVBNM',
      keyboard1: '京津冀鲁晋蒙辽吉黑沪苏浙皖闽赣豫鄂湘粤桂渝川贵云藏陕甘青琼新宁港澳台',
      keyboard2: '',
      keyboard2For: ['确认'],
      keyboardValue: '京津冀鲁晋蒙辽吉黑沪苏浙皖闽赣豫鄂湘粤桂渝川贵云藏陕甘青琼新宁港澳台'
    }
  },
  props: {
    name:String,
    plateNo:{
      type:String,
      default:""
    },
    maxLength:{
      type:Number,
      default:7
    },
    tapSpecBtn:{
      type:Function,
      default: function () {}
    },
    tapKeyboard:{
      type:Function,
      default: function () {}
    },
    specialBtn:{
      type:Boolean,
      default: false
    },
    tapNum:{
      type:Boolean,
      default: false
    }

  },
  methods:{
  },
  watch:{
    maxLength:function (){

    }
  },
  computed: {

  }
}
</script>

<style>
  .page {
  height: 100%;
}

.table {
  border: 0 solid darkgray;
  font-size: 12px;
  height: 100%;
  font-weight: 500;
}

.bg-top {
  background-color: #20BBA9;
  width: 100%;
  height: 50%;
}

.bg-f {
  width: 100%;
  height: 10%;
}

.ptmsg {
  color: #FFFFFF;
  font-size: 60px;
  margin-top: 3%;
  margin-left: 10%;
  width: 80%;
  height: 10%;
  text-align: center;
}

.warn-msg {
  width: 80%;
  font-size: 40px;
  margin-left: 10%;
  margin-top: 10%;
  color: gray;
}

.placeholder {
  color: #bdc3c7;
}

.text-view {
  background-color: #FFFFFF;
  width: 70%;
  height: 15%;
  margin-top: 10%;
  margin-left: 15%;
  border-radius: 15px;
  color: #000000;
  text-align: center;
  vertical-align: middle;
  font-size: 85px;
}

.bindplatenumber {
  background-color: #FFFFFF;
  width: 100%;
  height: 8%;
  text-align: center;
  vertical-align: middle;
  font-size: 60px;
}

.btn-view {
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
}

.btn {
  display: flex;
  background-color: #20BBA9;
  width: 50%;
  height: 100%;
  font-size: 50px;
  color: #FFFFFF;
  border-right-color: #FFFFFF;
  justify-content: center;
  align-items: center;
}

.keyboard {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #D1D5DA;
  display: -webkit-flex;
  flex-wrap: wrap;
  border-bottom: 25px solid #D1D5DA;
  border-top: 20px solid #D1D5DA;
  border-left: 15px solid #D1D5DA;

}

.td {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  width: 8.5% !important;
  height: 38px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 2px;
  border: 1px solid #cdd0d5;
  color: #1e1e1e;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 1px #cdd0d5;
}

.td2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  width: 9.5% !important;
  height: 38px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin: 2px;
  border: 1px solid #cdd0d5;
  color: #1e1e1e;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 2px 2px 1px #cdd0d5;
}
.td view{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


.board_bg {
  box-shadow: 0 0 0 #e5e5e5;
  background: #e5e5e5;
}

.board_bgNone{

}

.board_bg_spec {
  box-shadow: 0 0 0 #e5e5e5;
}

.del_icon {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.del_icon image{
  width: 100%;
  height: 100%;
}

.td_spec_fin {
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 18px;
  background: #12BB43;
  border-radius: 4px;
  flex: 1 1 20%;
  margin-right: 25px !important;
  margin-left: 3px !important;
}

.study {
  background: #FCBE02;
  border-radius: 4px;
}

.police {
  font-size: 18px;
  font-weight: 500;
  color: #FF0014;
  line-height: 18px;
}

.study_bg {
  background-color: #ffc000;
}

.tm{
  background-color: transparent !important;
  width: 30px !important;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
}


</style>
