<template>
  <div id="app">
    <router-view title="'$route.meta.title'"/>
  </div>
</template>

<script>
import {getAction, postAction} from '@/utils/request.js'
import {getUrlKey} from '@/utils/getUrlKey.js'

export default {
  data() {
    return {}
  },
  created() {
    /*    var hrt = document.documentElement.clientHeight; //获取当前可视区域的高度存到hrt变量
       window.onload = function(){ //在页面整体加载完毕时
           document.getElementById('app').style.height= hrt+'px'//把获取到的高度赋值给根div
       } */
    let channo = getUrlKey("channel_no");
    this.$store.commit('setChannelNo', channo)
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    if (window.sessionStorage.getItem("channelNo") && !this.$store.state.channelno) {
      this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
    }
    /*  var hrt = document.documentElement.clientHeight; //获取当前可视区域的高度存到hrt变量
      //在页面整体加载完毕时
      console.log(hrt)
         document.getElementsByClassName('pageactivity').style.height= hrt+'px'
        // document.getElementById('app').style.height= hrt+'px'//把获取到的高度赋值给根div */

    channo = this.$store.state.channelno;
    /**取主题颜色值 */
    getAction("/business/H5/queryTheme" + "?channel_no=" + channo)
        .then(response => {/*
         console.log(response); */
          let res = response.data.result;
          this.$store.commit('setThemeColor', res.themeColor);
          this.$store.commit('setThemeColor2', res.themeColor2);
        });
  },
  computed: {
    themecolor() {
      return this.$store.state.themecolor;
    },
    themecolor2() {
      return this.$store.state.themecolor2;
    }

  },
  watch: {
    themecolor(val) {
      document.getElementById("app").style.setProperty('--theme-color', val);
    },
    themecolor2(val) {
      document.getElementById("app").style.setProperty('--theme-color2', val);
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  //--theme-color: rgba(0, 96, 27, 1);
  //--theme-color2: rgba(7, 164, 51, 1);
  color: #2c3e50;
  height: 100%;
}

html, body {
  height: 100%;
  margin: 0 auto;
  padding: 0px
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}
</style>
