<template>
  <common-bank-my-prize ref="wxMyPrize" :query-parma="queryParma" :channel-no-parma="channelNoParma" @gotoDui="gotoDui($event)">
  </common-bank-my-prize>
</template>

<script>
import Home from "@/views/bank/result/Home";
import CommonBankMyPrize from "@/views/commonBankActivity/components/CommonBankMyPrize";

export default {
  name: "wxpayMyPrizeWrapper",
  components: {CommonBankMyPrize, Home},
  data() {
    return {
      queryParma: null,
      channelNoParma: null,
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    async init() {
      //在页面加载时读取sessionStorage里的状态信息
      if(window.sessionStorage.getItem("wxOpenId") && !this.$store.state.wxOpenId) {
        this.$store.state.wxOpenId = window.sessionStorage.getItem("wxOpenId")
      }
      if(window.sessionStorage.getItem("channelNo") && !this.$store.state.channelno) {
        this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("wxOpenId", this.$store.state.wxOpenId)
        window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
      })
      this.queryParma ="?wxOpenId="+ this.$store.state.wxOpenId ;
      this.channelNoParma ="&channel_no="+ this.$store.state.channelno ;

      this.$nextTick(()=>{
        this.$refs.wxMyPrize.ready();
      })
    },
    async gotoDui(ticketNo) {
      this.$router.push({name:'aboutWxpay', params:{ticno:ticketNo, wxOpenId:this.$store.state.wxOpenId}});
    },
  }
}
</script>

<style scoped>

</style>