import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vant from 'vant'
import 'vant/lib/index.css'
import {Image as VanImage} from 'vant'
import {Divider} from 'vant';
import {Toast} from 'vant';
import VueClipboard from 'vue-clipboard2'
import {axios} from 'axios'

// import VConsole from "vconsole";
//
// const vc = new VConsole({});

const app = createApp(App);
app.use(store).use(router).use(vant).use(VanImage).use(Toast).use(Divider).use(VueClipboard).mount('#app');
