<template>
  <common-bank-about ref="wxAbout" :exchange-parma="exchangeParma">
  </common-bank-about>
</template>

<script>
import CommonBankAbout from "@/views/commonBankActivity/components/CommonBankAboutView";

export default {
  name: "wxpayAboutWrapper",
  components: {CommonBankAbout},
  data() {
    return {
      exchangeParma: null,
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    async init() {
      //在页面加载时读取sessionStorage里的状态信息
      if(window.sessionStorage.getItem("wxOpenId")) {
        this.$store.state.wxOpenId = window.sessionStorage.getItem("wxOpenId")
      }
      if(window.sessionStorage.getItem("ticketNo")&& !this.$store.state.ticketno) {
        this.$store.state.ticketno = window.sessionStorage.getItem("ticketNo")
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("wxOpenId", this.$store.state.wxOpenId)
        window.sessionStorage.setItem("ticketNo", this.$store.state.ticketno)
      })
      this.exchangeParma ="?wxOpenId=" + this.$store.state.wxOpenId + "&ticket_no="+ this.$store.state.ticketno ;
      this.$nextTick(()=>{
        this.$refs.wxAbout.ready();
      })
    },
  }
}
</script>

<style scoped>

</style>