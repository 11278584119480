import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/bank/HomeView.vue'
import AboutView from '../views/bank/AboutView.vue'
import MyPrize from '../views/bank/MyPrize'
import Home from '../views/bank/result/Home.vue'
import Dag from '../views/bank/result/TempLogin.vue'
import HomeView2 from '../views/dadi/HomeView.vue'
import AboutView2 from '../views/dadi/AboutView.vue'
import MyPrize2 from '../views/dadi/MyPrize'
import CiticLoginWrapper from "@/views/commonBankActivity/citic/CiticLoginWrapper";
import CiticMyPrizeWrapper from "@/views/commonBankActivity/citic/CiticMyPrizeWrapper";
import CiticAboutWrapper from "@/views/commonBankActivity/citic/CiticAboutWrapper";

import WxpayLoginWrapper from "@/views/commonBankActivity/wxpay/WxpayLoginWrapper";
import WxpayMyPrizeWrapper from "@/views/commonBankActivity/wxpay/WxpayMyPrizeWrapper";
import WxpayAboutWrapper from "@/views/commonBankActivity/wxpay/WxpayAboutWrapper";

const routes = [
    {
        path: '/bank',
        name: 'home',
        component: Home,
        meta: {title: '活动主页'},
    },
    {
        path: '/dadi',
        name: 'activehome2',
        component: HomeView2,
        meta: {title: '活动主页'},
    },
    {
        path: '/bank/HomeView',
        name: 'activehome',
        component: HomeView,
        meta: {title: '活动主页'},
    },
    {
        path: '/bank/Dialog',
        name: 'dialog', /*临时授权*/
        component: Dag
    },

    {
        path: '/bank/AboutView',
        name: 'about',
        component: AboutView,
        meta: {title: '领取页面'},
    },
    {
        path: '/bank/MyPrize',
        name: 'myprize',
        component: MyPrize,
        meta: {title: '我的奖品'},
    },


    {
        path: '/dadi/AboutView',
        name: 'about2',
        component: AboutView2,
        meta: {title: '领取页面'},
    },
    {
        path: '/dadi/MyPrize',
        name: 'myprize2',
        component: MyPrize2,
        meta: {title: '我的奖品'},
    },


    {
        path: '/citic',
        name: 'citic',
        component: CiticLoginWrapper,
        meta: {title: '活动主页'},
    },
    {
        path: '/citic/AboutView',
        name: 'aboutCitic',
        component: CiticAboutWrapper,
        meta: {title: '领取页面'},
    },
    {
        path: '/citic/MyPrize',
        name: 'myprizeCitic',
        component: CiticMyPrizeWrapper,
        meta: {title: '我的奖品'},
    },

    {
        path: '/wxpay',
        name: 'wxpay',
        component: WxpayLoginWrapper,
        meta: {title: '活动主页'},
    },
    {
        path: '/wxpay/AboutView',
        name: 'aboutWxpay',
        component: WxpayAboutWrapper,
        meta: {title: '领取页面'},
    },
    {
        path: '/wxpay/MyPrize',
        name: 'myprizeWxpay',
        component: WxpayMyPrizeWrapper,
        meta: {title: '我的奖品'},
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {//判断是否有标题
        document.title = to.meta.title
    }
    next()
})

export default router
