<template>
  <common-bank-activity ref="infoComp" :access-status="accessStatus" @actvityOk="userAuthCheck($event)">
    <template v-slot:hasAuth>
      <button class="Button1activity flex-col" @click="paymentNow">
        <span class="word2activity">立即领取</span>
      </button>
    </template>
    <template v-slot:gotoPrize>
      <button class="ButtonP " style="bottom:60%;" @click="gotoPrize">
        <span class="infoBoxP">奖<br/>品</span>
      </button>
    </template>
  </common-bank-activity>
</template>

<script>
import Home from "@/views/bank/result/Home";
import {Dialog, Toast} from "vant";
import {getAction, postAction} from "@/utils/request";
import CommonBankActivity from "@/views/commonBankActivity/components/CommonBankActivity";
import router from "@/router";
/* import VConsole from 'vconsole';

let vConsole = new VConsole(); */

let timer;

export default {
  name: "citicLoginWrapper",
  components: {CommonBankActivity, Home},
  data() {
    return {
      //正在登录LOADING
      initLoading: true,
      //用户OPENID
      userOpenId: null,
      //活动状态
      accessStatus: null,
      //用户ID
      userId: null,
      //活动NO
      activityNo: null,
    }
  },
  created() {

    const _this = this;
    this.init()
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'hidden') {
        console.log('页面离开时触发');
      } else if (document.visibilityState === 'visible') {
        let param = localStorage.getItem("citic_redirect_param")
        if (param) {
          if (param !== '{}') {
            _this.selectPaymentResult();
            localStorage.removeItem("citic_redirect_param")
          }
        }
      }
    })
  },
  mounted() {

  },
  methods: {
    async init() {

      if (!this.$route.query.token) {
        Toast.fail({
          message: "参数非法",
          duration: 0,
          forbidClick: true
        })
        setTimeout(() => {
          this.$refs.infoComp.ready();
        }, 2000)
        return;
      }

      let userInfoKey = "citic_user_info" + this.$route.query.token;

      const storageUserInfo = sessionStorage.getItem(userInfoKey);

      if (storageUserInfo && storageUserInfo !== '{}') {

        console.log("取出原有用户信息", storageUserInfo)

        const storageUserInfoObj = JSON.parse(storageUserInfo);

        this.userId = storageUserInfoObj.userId
        this.userOpenId = storageUserInfoObj.userOpenId

        this.$nextTick(() => {
          this.$refs.infoComp.ready();
        })

        return;

      }

      Toast.loading({
        message: "正在加载",
        duration: 0,
        forbidClick: true
      })

      const {data} = await postAction("/citic/doLogin", {
        token: this.$route.query.token
      })

      if (!data.success) {
        Toast.fail({
          message: data.message
        })
        setTimeout(() => {
          this.$refs.infoComp.ready();
        }, 2000)
        return
      }

      this.userOpenId = data.result.openId
      this.userId = data.result.userId

      sessionStorage.setItem(userInfoKey, JSON.stringify({
        userId: data.result.userId,
        userOpenId: data.result.openId
      }))
      Toast.clear();

      this.$nextTick(() => {
        this.$refs.infoComp.ready();
      })

    },
    async userAuthCheck(activityNo) {

      this.activityNo = activityNo;

      console.log("用户权限校验", activityNo, this.userOpenId)

      if (!this.userOpenId) {
        this.accessStatus = "noPower";
        return "noPower";
      }

      const {data} = await postAction("/citic/citicUserAuthCheck", {
        activityNo: activityNo,
        openId: this.userOpenId
      })

      this.accessStatus = data.success ? 'OK' : data.message

      console.log("用户权限校验OK")

      let param = localStorage.getItem("citic_redirect_param")

      if (param) {

        if (param !== '{}') {

          this.selectPaymentResult();

          localStorage.removeItem("citic_redirect_param")

        }

      }

    },
    async paymentNow() {
      console.log("立即支付")

      Toast.loading({
        message: "正在拉起支付",
        duration: 0,
        forbidClick: true
      })

      const {data} = await postAction("/citic/doPayment", {
        userId: this.userId,
        activityNo: this.activityNo
      })

      if (!data.success) {
        Toast.fail({
          message: data.message,
          forbidClick: true,
          duration: 3000
        })
        return;
      }

      localStorage.setItem("citic_paymentOutTradeNo", data.result.orderInfo.outTradeNo);

      localStorage.setItem("citic_userId", this.userId);

      localStorage.setItem("citic_redirect_param", JSON.stringify({
        need: true,
        url: window.location.href
      }))

      let h5Url = data.result.paymentInfo.url;
      let ua = navigator.userAgent;
      let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        h5Url = h5Url + "&redirect_url=" + encodeURIComponent("http://jumpapp.uesugitech.com/citic.html")
      }

      window.location.href = h5Url

      // await this.selectPaymentResult();

    },
    async selectPaymentResult() {

      const outTradeNo = localStorage.getItem("citic_paymentOutTradeNo");

      const userId = localStorage.getItem("citic_userId")

      let count = 0;

      if (outTradeNo && userId) {

        Toast.loading({
          message: "正在查询支付记录",
          duration: 0,
          forbidClick: true
        })

        localStorage.removeItem("citic_paymentOutTradeNo")

        localStorage.removeItem("citic_userId")

        const _this = this;

        timer = setInterval(async () => {

          if (count > 20) {
            Toast.clear();
            clearInterval(timer)
            return;
          }

          count += 1;

          const {data} = await getAction("/citic/selectPaymentResult", {
            outTradeNo,
            userId
          })

          if (data.success) {

            Toast.loading({
              message: "正在跳转领取",
              forbidClick: true,
              duration: 0
            })

            clearInterval(timer)
            let ticketNo = data.result.ticketNo;
            this.$store.commit('setTicketno', ticketNo)
            sessionStorage.setItem("citicOpenId", this.userOpenId);
            sessionStorage.setItem("ticketNo", ticketNo);
            this.$router.push({
              path: "/citic/AboutView"
            })
            Toast.clear();

            //领取奖品
            /* postAction("/business/H5/receiveNew", {
              activityNo: this.activityNo,
              citicOpenId: this.userOpenId
            }).then(response => {
              let ticketNo = response.data.result.ticketNo;
              this.$store.commit('setTicketno', ticketNo)
              sessionStorage.setItem("citicOpenId", this.userOpenId);
              sessionStorage.setItem("ticketNo", ticketNo);
              console.log(response);
              this.$router.push({
                path: "/citic/AboutView"
              })
            }).finally(() => {
              Toast.clear();
            }) */

          } else if (data.code === 90002) {

            Toast.clear();

            clearInterval(timer)

            await Dialog({
              message: "您未使用中信银行卡支付，现已为您退款，款项原路退回请注意查收",
            })

          }

        }, 1000)

      }

    },
    async gotoPrize() {
      this.$store.commit('setCiticOpenId', this.userOpenId)
      this.$router.push({name: 'myprizeCitic', params: {citicOpenId: this.userOpenId}});
    }
  }
}
</script>

<style scoped>

</style>