<template>
  <common-bank-activity ref="infoComp" :access-status="accessStatus" @actvityOk="userAuthCheck($event)">
    <template v-slot:hasAuth>
      <button class="Button1activity flex-col" @click="toapply">
        <span class="word2activity">立即领取</span>
      </button>
    </template>
    <template v-slot:gotoPrize>
      <button class="ButtonP " style="bottom:60%;" @click="gotoPrize">
        <span class="infoBoxP">奖<br />品</span>
      </button>
    </template>
  </common-bank-activity>
</template>
<script>
import Home from "@/views/bank/result/Home";
import { Dialog, Toast } from "vant";
import { getAction, postAction } from "@/utils/request";
import CommonBankActivity from "@/views/commonBankActivity/components/CommonBankActivity";
import router from "@/router";
import wx from 'weixin-jsapi'
/* import VConsole from 'vconsole';

let vConsole = new VConsole();  */

let timer;

export default {
  name: "wxpayLoginWrapper",
  components: { CommonBankActivity, Home },
  data() {
    return {
      //正在登录LOADING
      initLoading: true,
      //用户OPENID
      userOpenId: null,
      //活动状态
      accessStatus: null,
      //用户ID
      userId: null,
      //活动NO
      activityNo: null,
      //用户CODE
      code: undefined,
    }
  },
  created() {
    const _this = this;
    this.init()
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'hidden') {
        console.log('页面离开时触发');
      } else if (document.visibilityState === 'visible') {
        console.log('页面回来时触发');
        let param = localStorage.getItem("wxpay_redirect_param")
        if (param) {
          if (param !== '{}') {
            _this.selectPaymentResult();
            localStorage.removeItem("wxpay_redirect_param")
          }
        }
      }
    })
  },
  mounted() {
  },
  methods: {
    async init() { // 非静默授权，第一次有弹框
      var local = window.location.href // 获取页面url
      var appid = 'wxb5b6ed10e7d47ef4'
      this.code = this.getUrlCode().code // 截取code
      if (this.code == undefined) { // 如果没有code，则去请求
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
      } else {
        if (!window.sessionStorage.getItem("userOpenId")) {
          await getAction("/wxpay/getOpenIdByCode", {
            code: this.code
          }).then(res => {
            localStorage.setItem("userOpenId", res.data.result)
            window.sessionStorage.setItem("userOpenId", res.data.result)
            this.userOpenId = res.data.result
            this.$nextTick(() => {
              this.$refs.infoComp.ready();
            })
          }).catch((e) => { });
        } else {
          this.userOpenId = window.sessionStorage.getItem("userOpenId")
          window.sessionStorage.setItem("userOpenId", this.userOpenId)
          this.$nextTick(() => {
            this.$refs.infoComp.ready();
          })
        }
      }
    },
    getUrlCode() { // 截取url中的code方法
      var url = location.search
      this.winUrl = url
      var theRequest = new Object()
      if (url.indexOf("?") != -1) {
        var str = url.substr(1)
        var strs = str.split("&")
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    async userAuthCheck(activityNo) {
      this.activityNo = activityNo;
      if (!this.userOpenId) {
        this.userOpenId = window.sessionStorage.getItem("userOpenId")
      }
      console.log("用户权限校验", activityNo, this.userOpenId)
      if (!this.userOpenId) {
        this.accessStatus = "noPower";
        return "noPower";
      }
      const { data } = await postAction("/wxpay/wxUserAuthCheck", {
        activityNo: activityNo,
        openId: window.sessionStorage.getItem("userOpenId")
      })
      this.accessStatus = data.success ? 'OK' : data.message
      console.log("用户权限校验OK")
      let param = localStorage.getItem("wxpay_redirect_param")
      if (param) {
        if (param !== '{}') {
          this.selectPaymentResult();
          localStorage.removeItem("wxpay_redirect_param")
        }
      }
    },
    async toapply() {
      console.log("立即支付")
      Toast.loading({
        message: "正在拉起支付",
        duration: 0,
        forbidClick: true
      })
      const { data } = await postAction("/wxpay/doPayment", {
        userOpenId: this.userOpenId,
        activityNo: this.activityNo,
        url: window.location.href.split('#')[0]
      })
      console.log("data-----------:", data);
      if (!data.success) {
        Toast.fail({
          message: data.message,
          forbidClick: true,
          duration: 1000
        })
        return;
      } else {
        console.log("outTradeNo-----------:", data.result.result.outTradeNo);
        const wxdata = data.result.result
        wx.config({
          // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来
          appId: wxdata.appId,
          timestamp: wxdata.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: wxdata.nonceStr, // 支付签名随机串，不长于 32 
          signature: wxdata.configSign,
          jsApiList: ['chooseWXPay'] // 必填，需要使用的 JS 接口列表
        })
        wx.ready(function () {
          wx.chooseWXPay({
            appId: data.result.appId,
            timestamp: wxdata.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: wxdata.nonceStr, // 支付签名随机串，不长于 32 
            package: 'prepay_id=' + wxdata.prepayId, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: wxdata.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: wxdata.paySign, // 支付签名
            success: function (res) {
              console.log('支付成功', res);
              Toast.clear();
              localStorage.setItem("wxpay_paymentOutTradeNo", wxdata.outTradeNo);
              localStorage.setItem("wxpay_redirect_param", JSON.stringify({
                need: true,
                url: window.location.href
              }))
              this.selectPaymentResult()
            },
            cancel: function (res) {//提示引用的是mint-UI里toast
              console.log('已取消支付', res);
              Toast.clear();
              Toast.fail({
                message: '已取消支付',
                forbidClick: true,
                duration: 2000
              })
            },
            fail: function (res) {
              this.isLock = false
              Toast.clear();
              Toast.fail({
                message: '支付失败，请重试',
                forbidClick: true,
                duration: 2000
              })
            }
          })
        });
      }
    },
    async selectPaymentResult() {

      const outTradeNo = localStorage.getItem("wxpay_paymentOutTradeNo");

      const userId = window.sessionStorage.getItem("userOpenId")

      let count = 0;

      if (outTradeNo && userId) {

        Toast.loading({
          message: "正在查询支付记录",
          duration: 0,
          forbidClick: true
        })

        localStorage.removeItem("wxpay_paymentOutTradeNo")

        const _this = this;

        timer = setInterval(async () => {

          if (count > 20) {
            Toast.clear();
            clearInterval(timer)
            return;
          }

          count += 1;

          const { data } = await getAction("/wxpay/selectPaymentResult", {
            outTradeNo,
            userId
          })

          if (data.success) {

            Toast.loading({
              message: "正在跳转领取",
              forbidClick: true,
              duration: 0
            })

            clearInterval(timer)
            let ticketNo = data.result.ticketNo;
            this.$store.commit('setTicketno', ticketNo)
            sessionStorage.setItem("wxOpenId", this.userOpenId);
            sessionStorage.setItem("ticketNo", ticketNo);
            this.$router.push({
              path: "/wxpay/AboutView"
            })
            Toast.clear();
          }

        }, 1000)

      }

    },
    async gotoPrize() {
      this.$store.commit('setWxOpenId', this.userOpenId)
      this.$router.push({ name: 'myprizeWxpay', params: { wxOpenId: this.userOpenId } });
    }
  }
}
</script>

<style scoped>

</style>