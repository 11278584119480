<template>
  <common-bank-my-prize ref="citicMyPrize" :query-parma="queryParma" :channel-no-parma="channelNoParma" @gotoDui="gotoDui($event)">
  </common-bank-my-prize>
</template>

<script>
import Home from "@/views/bank/result/Home";
import CommonBankMyPrize from "@/views/commonBankActivity/components/CommonBankMyPrize";

export default {
  name: "citicMyPrizeWrapper",
  components: {CommonBankMyPrize, Home},
  data() {
    return {
      queryParma: null,
      channelNoParma: null,
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  methods: {
    async init() {
      //在页面加载时读取sessionStorage里的状态信息
      if(window.sessionStorage.getItem("citicOpenId") && !this.$store.state.citicOpenId) {
        this.$store.state.citicOpenId = window.sessionStorage.getItem("citicOpenId")
      }
      if(window.sessionStorage.getItem("channelNo") && !this.$store.state.channelno) {
        this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("citicOpenId", this.$store.state.citicOpenId)
        window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
      })
      this.queryParma ="?citicOpenId="+ this.$store.state.citicOpenId ;
      this.channelNoParma ="&channel_no="+ this.$store.state.channelno ;

      this.$nextTick(()=>{
        this.$refs.citicMyPrize.ready();
      })
    },
    async gotoDui(ticketNo) {
      this.$router.push({name:'aboutCitic', params:{ticno:ticketNo, citicOpenId:this.$store.state.citicOpenId}});
    },
  }
}
</script>

<style scoped>

</style>