<template>

  <div class="pageactivity flex-col">
    <div class="layeractivity flex-col" :style="acbanner">
      <div class="frame flex-col">
        <div class="pic1ac">
          <van-field  v-model="phoneNumber" placeholder="请输入手机号领取奖品"  style="border-radius:44px; text-align: center;"  ></van-field>
        </div>

        <button  class="Button1activity flex-col" style="position: absolute;top: 120px"  @click="submit()">
          <span class="word2activity">领取奖品</span>
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import {Dialog as VanDialog }  from 'vant'
import {Field as VanField} from 'vant'
import {postAction} from '@/utils/request.js'


  export default {
    name: "TempLogin",
    components:{
        VanDialog,
        VanField,
    },
  
    data () {
      return {
        title:"操作",
        visible: true,
        model: {},
        phoneNumber:'',
        acbanner:{
          backgroundColor : 'red',
          backgroundRepeat : 'no-repeat',
          backgroundSize : '100% 100%',
        }
      }
    },
    created () {
    },
    methods: {
        submit(){
            var reg_tel = /^(1)\d{10}$/; 
            if(!reg_tel.test(this.phoneNumber)){
              this.$toast({
                duration: 3000,
                message: '手机号输入有误！'
              })
              return;
            }
            this.$store.commit('setPhonenumber',this.phoneNumber)
            console.log(this.$store.state.phonenumber)
            //用户判断，如不存在新建用户
            postAction("/business/H5/noExistCreateUser?phone_number="+this.phoneNumber).then(response=>{
                this.$router.push({name:'activehome',params:{myphone:response.data.result.phoneNumber}});
                // this.$router.push('/HomeView');
                })

            
        },
      
    }
  }
</script>
<style src="@/assets/css/activity_common.css" />
<style>
.van-field__control{
  text-align: center!important;
}
.pic1ac{
  text-align: center;
  width: 92vw;
  height: 5.6vh;
  margin:0 auto;
  border-radius:44px;
  position: relative;
  /*background-color: pink;*/
}
.word2activity {
  width: 12.54vw;
  height: 3.6vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 3.73vw;
  font-family: SourceHanSansCN-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 3.74vw;
  display: block;
  margin: auto;
}
.Button1activity {
  background-image: linear-gradient(180deg,var(--theme-color) 0,var(--theme-color) 0,var(--theme-color2) 99.316406%,var(--theme-color2) 100%);
  border-radius: 44px;
  width: 92vw;
  height: 5.6vh;
  position: absolute;
  left:50%;
  transform: translate(-50%);
  margin-left: 0 !important;
}
</style>