<template>

   <div class="pageactivity flex-col">
    <div class="layeractivity flex-col" :style="Acbanner">
      <div class="frame flex-col">
        <!-- <img
          class="pic1activity"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/psmn5giufvpun0b8rhhaxdnh0eiv2n3uqsb8fc363a-f4f7-4297-afb3-211a58a2b92a"
        /> -->
      <div class="pic1activity ">
       <!--  <span class="artword2" >{{resData1.activityTitle}}</span>   -->
      </div>
        <div class="section1activity flex-col">
          <span class="info1activity">{{activStart}}-{{activEnd}}</span>
        </div>
       <div  v-if="accessstatus.message!='noPhone'" style="position: absolute;right: 0rem;">
        <button class="ButtonP " style="bottom:60%;" @click="gotoPrize()" >
              <span class="infoBoxP">奖<br/>品</span>
        </button>
        </div>
        <div  v-if="accessstatus.message=='noPhone'" style="position: absolute;right: 0rem;">
        <button class="ButtonP " style="bottom:60%;" @click="getphone2()" >
              <span class="infoBoxP">奖<br/>品</span>
        </button>
        </div>
        <div class="section2activity flex-col">
          <span class="word1activity">活动说明</span>
        </div>
        <!-- <van-popup v-model:show="showModal" position="bottom" :style="{ height: '100vw' ,'border-radius':'10px',}">
        <div :style="{ height: '20%' }">
        <br/>
        <p>请输入车牌号</p>
        <br/>
          <van-form @submit="onSubmit" >
         <van-cell-group inset>
           <van-field round readonly v-model="shengVal" maxlength="1" style=" width: 8%;left:3%;padding:7px 2px;font-size: 22px;float: left;" :style="!specialBtn&&!tapNum?'border:1px solid red;':'border:1px solid #acacac;'" placeholder="" @click="shengClick"></van-field>
           <van-field round readonly v-model="shiVal" maxlength="1" style=" width: 8%;left:3%;padding:7px 4px;font-size: 22px;float: left;margin-left: 15px;" :style="specialBtn&&!tapNum?'border:1px solid red;':'border:1px solid #acacac;'" placeholder="" @click="shiClick"></van-field>
           <span style="background-color: black;width: 10px;height: 10px;border-radius: 10px;float: left;margin-left: 25px;margin-top: 15px;"></span>
           <van-field round readonly v-model="numVal" maxlength="6" style=" width: 65%;left:3%;padding:7px 7px;font-size: 22px;float: left;" :style="specialBtn&&tapNum?'border:1px solid red;':'border:1px solid #acacac;'" placeholder="" @click="numClick"></van-field>
          </van-cell-group>
           <div style="margin: 3vh 1vh;">
            <van-button round block type="primary" style="background-color:var(--theme-color)" :style="{ width: '90%' ,left:'5%' }" native-type="submit">
               领取
            </van-button>
          </div>
          </van-form>
          </div>
          <PlateNoInput :tapKeyboard="tapKeyboard" :specialBtn="specialBtn" :tapNum="tapNum"></PlateNoInput>
        </van-popup> -->
        <div class="modelMask"  v-show="showModal">
          <div class="plateNumberModel">
            <div class="plateTitle">
              请输入车牌号
              <img src="https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/techManage/closed.png" @click="closePlateNumberModel">
            </div>
            <div class="plate-no-input">
              <span @click="showKeyboard(index)" v-bind:class=" (clickIndex === index ? 'activate' : '') + ' plate-no-num' + index " class="plate-no-num " v-for="(i,index) in maxLength">{{ plateNo[index] || '' }}</span>
              <img @click="setMaxLength"  class="new-enerpy" data-max="8" src="https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/prePay/clean-energy.png"
                  v-if="maxLength<8"/>
            </div>
            <!-- <div class="vehicleType">
              <div>车辆类型</div>
              <div class="vehicleTypeBox" @click="selectVehicleType(1)" data-type="1">
                <img :src="vehicleSizeType === 1?'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/zhekou/checked.png':'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/CouponPackage/quan.png'"/>
                <div>小型车</div>
              </div>
              <div class="vehicleTypeBox" @click="selectVehicleType(2)" data-type="2">
                <img :src="vehicleSizeType === 2?'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/zhekou/checked.png':'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/CouponPackage/quan.png'"/>
                <div>中型车</div>
              </div>
              <div class="vehicleTypeBox" @click="selectVehicleType(3)" data-type="3">
                <img :src="vehicleSizeType === 3?'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/zhekou/checked.png':'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/CouponPackage/quan.png'"/>
                <div>大型车</div>
              </div>
            </div> -->
            <div class="receive" @click="onSubmit">
              领取
            </div>
            <PlateNoInput :tapKeyboard="tapKeyboard" :specialBtn="specialBtn" :tapNum="tapNum"></PlateNoInput>
          </div>
        </div>
        <!-- <van-popup v-model:show="showKeboard" position="bottom" :style="{ height: '100vw' ,'border-radius':'10px',}">
        <div :style="{ height: '20%' }">
        <br/>
        <p>请输入手机号</p>
        <br/>
          <van-form @submit="onKeyboard" >
         <van-cell-group inset>
          <van-field round readonly v-model="shengVal" maxlength="1" style=" width: 8%;left:3%;padding:7px 2px;font-size: 22px;float: left;" :style="!specialBtn&&!tapNum?'border:1px solid red;':'border:1px solid #acacac;'" placeholder="" @click="shengClick"></van-field>
          <van-field round readonly v-model="shiVal" maxlength="1" style=" width: 8%;left:3%;padding:7px 4px;font-size: 22px;float: left;margin-left: 15px;" :style="specialBtn&&!tapNum?'border:1px solid red;':'border:1px solid #acacac;'" placeholder="" @click="shiClick"></van-field>
          <span style="background-color: black;width: 10px;height: 10px;border-radius: 10px;float: left;margin-left: 25px;margin-top: 15px;"></span>
          <van-field round readonly v-model="numVal" maxlength="6" style=" width: 65%;left:3%;padding:7px 7px;font-size: 22px;float: left;" :style="specialBtn&&tapNum?'border:1px solid red;':'border:1px solid #acacac;'" placeholder="" @click="numClick"></van-field>
         </van-cell-group>
           <div style="margin: 3vh 1vh;">
            <van-button round block type="primary" style="background-color:var(--theme-color)" :style="{ width: '90%' ,left:'5%' }" native-type="submit">
               确认
            </van-button>
          </div>
          </van-form>
          </div>
          <PlateNoInput :tapKeyboard="tapKeyboard" :specialBtn="specialBtn" :tapNum="tapNum"></PlateNoInput>
        </van-popup> -->
        <div class="modelMask"  v-show="showKeboard">
          <div class="plateNumberModel">
            <div class="plateTitle">
              请输入车牌号
              <img src="https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/techManage/closed.png" @click="closePlateNumberModel">
            </div>
            <div class="plate-no-input">
              <span @click="showKeyboard(index)" v-bind:class=" (clickIndex === index ? 'activate' : '') + ' plate-no-num' + index " class="plate-no-num " v-for="(i,index) in maxLength">{{ plateNo[index] || '' }}</span>
              <img @click="setMaxLength"  class="new-enerpy" data-max="8" src="https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/prePay/clean-energy.png"
                  v-if="maxLength<8"/>
            </div>
            <!-- <div class="vehicleType">
              <div>车辆类型</div>
              <div class="vehicleTypeBox" @click="selectVehicleType(1)" data-type="1">
                <img :src="vehicleSizeType === 1?'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/zhekou/checked.png':'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/CouponPackage/quan.png'"/>
                <div>小型车</div>
              </div>
              <div class="vehicleTypeBox" @click="selectVehicleType(2)" data-type="2">
                <img :src="vehicleSizeType === 2?'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/zhekou/checked.png':'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/CouponPackage/quan.png'"/>
                <div>中型车</div>
              </div>
              <div class="vehicleTypeBox" @click="selectVehicleType(3)" data-type="3">
                <img :src="vehicleSizeType === 3?'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/zhekou/checked.png':'https://tn-images.oss-cn-beijing.aliyuncs.com/minProgram/main/CouponPackage/quan.png'"/>
                <div>大型车</div>
              </div>
            </div> -->
            <div class="receive" @click="onKeyboard">
              确认
            </div>
            <PlateNoInput :tapKeyboard="tapKeyboard" :specialBtn="specialBtn" :tapNum="tapNum"></PlateNoInput>
          </div>
        </div>
       
        <div class="section4activity flex-col">
          <span class="txt1activity">本期活动奖品</span>
        </div>

        <button class="Button1activity flex-col" @click="getphone()">
          <span class="word2activity">立即领取</span>
        </button>

      </div>
      <div class="acontent flex-col">
        <span class="content" >{{resData1.activityContent}}<p>&nbsp;</p> </span>
       
      </div>
      <div class="explain flex-col">
       <!--  <div class="bd1activity flex-row justify-between"> -->
          <img
            class="img1activity"
            referrerpolicy="no-referrer"
            :src="resData1.prizePic"
          />
         <!--  <div class="mod1activity flex-col justify-between">
            <span class="word3activity">{{resData1.prizeContent}}</span>
            <span class="txt2activity">价值{{resData1.prizePrice}}元</span>
          </div> -->
        
      </div>
    </div>
  </div>
</template>

<script>
import PlateNoInput from '@/components/plateNoInput.vue'
import {Button,Popup,NumberKeyboard } from 'vant'
 import moment from 'dayjs'
import { toStringJSON } from 'xe-utils/methods'
import {getAction,postAction} from '@/utils/request.js'


export default {
  name: 'HomeView',
  components: {
    PlateNoInput,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [NumberKeyboard.name]: NumberKeyboard,
  },
   data () {
      return {
        description: '活动页面',
        resData1:[],
        prizepic:"",
        showModal:false,
        showKeboard:false,
       accessstatus:[],
       Acbanner:{
         backgroundImage : '',
        backgroundRepeat : 'no-repeat',
        backgroundSize : '100% 100%',
        },
      specialBtn: false,
      tapNum: false,
      shengVal: '',
      shiVal: '',
      numVal:'',
      carNum:'',
      maxLength:7,
      plateNo:['','','','','','',''],
      clickIndex:0,
      }
   },
   created(){
     var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      
    }else{
    alert("本活动主页应该在微信内部打开");
    };
   
 
    //在页面加载时读取sessionStorage里的状态信息
    if(window.sessionStorage.getItem("channelNo")&& !this.$store.state.channelno) {
   		this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
   } 
   if(window.sessionStorage.getItem("activityNo")&& !this.$store.state.activityno) {
   		this.$store.state.activityno = window.sessionStorage.getItem("activityNo")
   } 
    if(window.sessionStorage.getItem("carNum")&&!this.$store.state.carNum) {
   		this.$store.state.carNum = window.sessionStorage.getItem("carNum")
   }
   //在页面刷新时将vuex里的信息保存到sessionStorage里
   window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
        window.sessionStorage.setItem("activityNo", this.$store.state.activityno)
        window.sessionStorage.setItem("carNum", this.$store.state.carNum)
        window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
   })
   this.carNum = this.$store.state.carNum;
  
    let channo=this.$store.state.channelno;
    
//当前活动信息
     postAction("/business/H5/curactive"+"?channel_no="+channo)
       .then(response=>{
         this.resData1 = response.data.result;
         this.activStart=moment(response.data.result.activityStart).format('YYYY.MM.DD');
         this.activEnd=moment(response.data.result.activityEnd).format('YYYY.MM.DD');
         this.datevalid=toStringJSON(response.data.result.validityDateJson);
         this.Acbanner.backgroundImage='url(' + this.resData1.activityBanner + ')';
           console.log(response);
           let acno=response.data.result.activityNo;
          this.$store.commit('setActivityno',acno)
           if(!this.carNum){
             console.log("noPhone");
             this.accessstatus.message="noPhone"
           }
           else{
            postAction("/business/H5/receivePower2",{activityNo:acno,carNum:this.carNum}).then(response=>{
                this.accessstatus=response.data;
                console.log(response);
              }).catch(err=>{
                this.accessstatus=response.data;
              }) ;
           }
         
       }) ;

		},
  methods: {
    gotoPrize()
    {
        this.$router.push({name:'myprize2',params:{carNum:this.$store.state.carNum}});
    },
    getphone()
    {
      this.showModal = !this.showModal;
      this.plateNo = ['','','','','','','']
      this.maxLength = 7;
      this.showKeyboard(0)
    },
    closeme(){
      this.showModal = !this.showModal;
    },
    getphone2()
    {
      this.showKeboard = !this.showKeboard;
      this.plateNo = ['','','','','','','']
      this.maxLength = 7;
      this.showKeyboard(0)
    },
    onSubmit() {
      for(var i=0;i<this.plateNo.length;i++){
        if(i<7 && !this.plateNo[i]){
          this.accessstatus.message = "noPhone"
          this.$toast({
            duration: 3000,
            message: '车牌号输入有误！'
          })
          return;
        }
      }

        var carNum = this.plateNo.join('');
        this.$store.commit('setCarNum', carNum)
        console.log(this.$store.state.carNum)
        this.accessstatus.message = "hasPhone"
        const that = this
        let acno = this.$store.state.activityno;
        this.$toast.loading({
          duration: 5000,
          message: '加载中...'
        });
        //用户判断，如不存在新建用户
        postAction("/business/H5/receiveWhitelist", { activityNo: acno, carNum: carNum}).then(response => {
          console.log(response);

          if (response.data.success) {
            let ticketNo = response.data.result.ticketNo;
            this.$store.commit('setTicketno', ticketNo)

            this.showModal = !this.showModal;
            this.$toast({
              duration: 3000,
              message: '领取成功！'
            })
            this.$router.push({ name: 'about2', params: { ticno: ticketNo, carNum: carNum} });
          }
          else {
            this.$toast({
              duration: 3000,
              message: response.data.message
            })
          }
        });
      
    },
    onKeyboard() {
      var carNum = this.plateNo.join('');
      for(var i=0;i<this.plateNo.length;i++){
        if(i<7 && !this.plateNo[i]){
          this.accessstatus.message = "noPhone"
          this.$toast({
            duration: 3000,
            message: '车牌号输入有误！'
          })
          return;
        }
      }

      var carNum = this.plateNo.join('');
      this.$store.commit('setCarNum', carNum)
      console.log(this.$store.state.carNum)
      this.accessstatus.message = "hasPhone"
      this.showKeboard = !this.showKeboard;
      this.gotoPrize();
      
    },
    tapKeyboard(itemName,index){
      if(!this.specialBtn){
        this.plateNo[this.clickIndex] = itemName;
        this.clickIndex++;
        this.specialBtn = true;
      }else{
        if(!this.tapNum){
          if(index!=19){
            this.plateNo[this.clickIndex] = itemName;
            this.clickIndex++;
            this.tapNum = true;
          }else{
            var flg = true;
            for(var i =this.plateNo.length;i>-1;i--){
              if(this.plateNo[i]){
                this.plateNo[i] = ''
                this.showKeyboard(i)
                flg = false;
                break;
              }
            }
            if(flg){
              this.showKeyboard(0)
            }
          }
          
        }else{
          if(index!=19){
            if(this.clickIndex<this.maxLength-1){
              this.plateNo[this.clickIndex] = itemName;
              this.clickIndex++;
            }else if(this.clickIndex==this.maxLength-1){
              this.plateNo[this.clickIndex] = itemName;
            }
            
          }else{
            var flg = true;
            for(var i =this.plateNo.length;i>-1;i--){
              if(this.plateNo[i]){
                this.plateNo[i] = ''
                this.showKeyboard(i)
                flg = false;
                break;
              }
            }
            if(flg){
              this.showKeyboard(0)
            }
          }
          
        }
        
      }
      
    },
    closePlateNumberModel(){
      this.showModal = false;
      this.showKeboard = false;
    },
    showKeyboard(e){
      if(e==0){
        this.specialBtn = false;
        this.tapNum = false;
      }else if(e == 1){
        this.specialBtn = true;
        this.tapNum = false;
      }else{
        this.specialBtn = true;
        this.tapNum = true;
      }
      this.clickIndex = e;
    },
    setMaxLength(){
      this.maxLength++;
      this.plateNo.push('')
      if(this.clickIndex==6){
        this.clickIndex++;
      }
    }
  }
}
</script>
<style>
.van-field__control{
  border:2px solid #acacac;
  border-radius:30px;
  padding: 7px 7px;
}
.van-cell{
  padding:0px;
}
.plateNumberModel {
  border-radius: 10px;
  width: 335px;
  height: 231px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 35vh auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}

.plateTitle {
  height: 49px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px #F5F5F5 solid;
  position: relative;
}
.plateTitle img{
  width: 15px;
  height: 15px;
  position: absolute;
  right: 15px;
}

.buttonModelText {
  color: white;
  font-size: 14px;
}
.plate-no-input {
   margin-top: 25px;
   margin-bottom: 14px;
   display: flex;
   justify-content: center;
 }
.plate-no-input .plate-no-num {
  border: 1px solid #999999;
  box-sizing: border-box;
  width: 33px;
  height: 40px;
  vertical-align: top;
  margin: 0 2px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
.plate-no-input .plate-no-num1 {
  position: relative;
  margin-right: 9px;
}
.plate-no-input .plate-no-num1::after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ABABAB;
  position: absolute;
  top: 18px;
  right: -8px;
}
.plate-no-input .new-enerpy {
  width: 33px;
  height: 40px;
  vertical-align: top;
  border-left: 0.0625rem solid #fff;
}

.vehicleType{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  font-size: 14px;
}

.vehicleTypeBox{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #030303;
  margin-right: 3px;
}
.vehicleTypeBox img{
  width: 15px;
  height: 15px;
  margin-right: 8px;
  margin-left: 15px;
}
.autoPayCloseImg{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 15px;
  height: 15px;
  margin-right: 8px;
  margin-left: 15px;
}
.autoPayModelImg{
  padding-top: 30px;
}
.autoPayDontaiTop{
  margin-top: 25px;
}
.autoPayDontai{
  font-size: 14px;
}
.receive{
  width: 232px;
  height: 44px;
  background: #12BB43;
  border-radius: 22px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-top: 23px;
}

.autoPayButtonModel {
  width: 200px;
  background-color: #12bb43;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px 25px 25px 25px;
  text-align: center;
  /*margin-bottom: 15px;*/
  margin-top: 20px;
}
.modelMask{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 999;
}
.activate{
  border-color: #12bb43 !important;
}
</style>


<style src="@/assets/css/activity_common.css" />
<style src="@/assets/css/activity.css" />