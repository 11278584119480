<template>

  <div class="pageactivity flex-col" v-if="visible">
    <div class="layeractivity flex-col" :style="Acbanner">
      <div class="frame flex-col" >
        <!-- <img
          class="pic1activity"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/psmn5giufvpun0b8rhhaxdnh0eiv2n3uqsb8fc363a-f4f7-4297-afb3-211a58a2b92a"
        /> -->
        <div class="pic1activity ">
          <!--  <span class="artword2" >{{resData1.activityTitle}}</span>   -->
        </div>
        <div class="section1activity flex-col">
          <span class="info1activity">{{ activStart }}-{{ activEnd }}</span>
        </div>
        <slot style="position: absolute;right: 0rem;" name="gotoPrize">
          <button class="ButtonP " style="bottom:60%;" @click="gotoPrize()">
            <span class="infoBoxP">奖<br/>品</span>
          </button>
        </slot>
        <div class="section2activity flex-col">
          <span class="word1activity">活动说明</span>
        </div>

        <div class="section4activity flex-col">
          <span class="txt1activity">本期活动奖品</span>
        </div>

        <div class="new_content" style="position: absolute;bottom: 40px">
          <div class="company">
            该活动由上杉科技（大连）有限公司提供技术服务
          </div>
          <div class="cooperation">
            <span class="title"> 商务合作热线 ：</span>
            <span class="hotline" > <a :href="'tel:' + 18940983023">18940983023</a></span>
<!--            <span class="hotline" > 18940983023</span>-->
            <span class="pic">

            </span>
          </div>

        </div>

        <slot v-if="accessStatus === 'OK'" name="hasAuth">
          <button class="Button1activity flex-col" @click="goTo()" style="position: relative">
            <span class="word2activity">立即领取</span>
          </button>
        </slot>

        <span v-else-if="accessStatus==='noStart'" class="Button1activity flex-col">
          <span class="word2activity3">本期活动未开始</span>
        </span>
        <span v-else-if="accessStatus==='over'" class="Button1activity flex-col">
          <span class="word2activity3">本期活动已结束</span>
        </span>
        <span v-else-if="accessStatus==='noPower'" class="Button1activity flex-col">
          <span class="word2activity2">用户无权益</span>
        </span>
        <span v-else-if="accessStatus==='powerOver'" class="Button1activity flex-col">
          <span class="word2activity2">权益已用完</span>
        </span>
        <span v-else-if="accessStatus==='todayNoPower'" class="Button1activity flex-col">
          <span class="word2activity3">今日权益已用完</span>
        </span>
        <span v-else-if="accessStatus==='todayNoPrize'" class="Button1activity flex-col">
          <span class="word2activity3">今日奖品已领完</span>
        </span>
        <span v-else-if="accessStatus==='noPrize'" class="Button1activity flex-col">
          <text class="word2activity2">奖品已被领光</text>
        </span>

      </div>
      <div class="acontent flex-col">
        <span class="content">{{ resData1.activityContent }}</span>
      </div>
      <div class="explain flex-col">
        <img
            class="img1activity"
            referrerpolicy="no-referrer"
            :src="resData1.prizePic"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import {Button, Toast} from 'vant'
import moment from 'dayjs'
import {toStringJSON} from 'xe-utils/methods'
import {postAction} from '@/utils/request.js'


export default {
  name: 'CommonBankActivity',
  components: {
    HelloWorld,
    [Button.name]: Button
  },
  props: {
    //用户活动权限
    accessStatus: {
      type: String
    },
  },
  data() {
    return {
      visible: false,
      description: '活动页面',
      resData1: [],
      prizepic: "",
      Acbanner: {
        backgroundImage: '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      },
      phoneNumber: ''
    }
  },
  async mounted() {

    //在页面加载时读取sessionStorage里的状态信息
    if (window.sessionStorage.getItem("phoneNumber") && !this.$store.state.phonenumber) {
      this.$store.state.phonenumber = window.sessionStorage.getItem("phoneNumber")
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      window.sessionStorage.setItem("phoneNumber", this.$store.state.phonenumber)
    })

    if (window.sessionStorage.getItem("channelNo") && !this.$store.state.channelno) {
      this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
    }

    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
    })

    this.phoneNumber = this.$store.state.phonenumber;
    console.log(this.phoneNumber);


  },
  methods: {
    async ready() {

      this.visible = true;

      let channo = this.$store.state.channelno;

      console.log(channo);

      Toast.loading({
        message: "正在加载",
        duration: 0,
        forbidClick: true
      })

      //当前活动信息
      let {data} = await postAction("/business/H5/curactive" + "?channel_no=" + channo);

      console.log("活动信息获取 ---->>>", data)

      this.resData1 = data.result;
      this.activStart = moment(data.result.activityStart).format('YYYY.MM.DD');
      this.activEnd = moment(data.result.activityEnd).format('YYYY.MM.DD');
      this.datevalid = toStringJSON(data.result.validityDateJson);
      this.Acbanner.backgroundImage = 'url(' + this.resData1.activityBanner + ')';
      let acno = data.result.activityNo;
      let pho = this.phoneNumber;

      this.$emit("actvityOk", acno);

      Toast.clear();

    },
    goTo() {
      let acno = this.resData1.activityNo;
      let pho = this.phoneNumber;
      //领取奖品
      postAction("/business/H5/receiveNew", {activityNo: acno, phoneNumber: pho}).then(response => {
        let ticketNo = response.data.result.ticketNo;
        this.$store.commit('setTicketno', ticketNo)
        console.log(response);
        this.$router.push({name: 'about', params: {ticno: ticketNo}});
      });

      //跳转
      //this.$router.push('/AboutView');

    },
    gotoPrize() {
      //直接跳转
      //this.$router.push('/MyPrize');
      this.$store.commit('setPhonenumber', this.phoneNumber)
      this.$router.push({name: 'myprize', params: {myphone: this.phoneNumber}});
    },
    goToJoin() {

    },
  }
}
</script>

<style src="@/assets/css/activity_common.css"/>
<style src="@/assets/css/activity.css"/>