<template>
  <div class="page flex-col">
    <div class="group1 flex-col">
      <div class="box2 flex-col">
        <div class="section1 flex-row justify-between">
          <button class="txt1" @click="exchangde_will()" v-bind:class="{word3:btn_staut==1}">待领取</button>
          <button class="txt1" @click="hasexchangded()" v-bind:class="{word3:btn_staut==2}">已领取</button>
          <button class="txt1" @click="hasdateout()" v-bind:class="{word3:btn_staut==3}">已过期</button>
        </div>
        <div v-if="btn_staut==3" class="tri2 flex-row">
          <div class="layer1 flex-col"></div>
        </div>
        <div v-else-if="btn_staut==2" class="tri21 flex-row">
          <div class="layer1 flex-col"></div>
        </div>
        <div v-else class="tri22 flex-row">
          <div class="layer1 flex-col"></div>
        </div>
      </div>
      <div class="Body flex-col">
        <div v-if="loopData0.length==0">
          <div style="margin-top:15%">
            <img src="@/assets/noprize.svg" />
          </div>
          <div>
            <span style="font-size: 5vw;font-family: PingFang SC-Regular, PingFang SC;font-weight: 400;color: #666666;line-height: 42px;text-align: center;">暂无数据</span>
          </div>

        </div>
        <div class="box1 flex-col justify-between">
          <div class="box3 flex-col">
            <div class="layer2 flex-col" v-for="(item, index) in loopData0" :key="index">
              <div class="group2 flex-col">
                <div class="mod1 flex-row">
                  <img v-if="btn_staut==1" class="pic2" referrerpolicy="no-referrer" :src="item.prizePic"
                    @click="gotodui(index)" />
                  <img v-if="btn_staut==2" class="pic2" referrerpolicy="no-referrer" :src="item.prizePic"
                    @click="gotoWXmin()" />
                  <img v-if="btn_staut==3" class="pic2gray" referrerpolicy="no-referrer" :src="item.prizePic" />
                </div>
                <div v-if="has_ex_staut" class="modd flex-row justify-between">
                  <span class="word5">领取时间：{{item.ticketConvertTime}}</span>
                  <button class="Button1 flex-col" @click="gotoWXmin()">
                    <span class="txt5">去查看</span>
                  </button>
                </div>

                <div v-else class="modd flex-row justify-between">
                  <span class="word5">有效时间：{{item.ticketConvertStart}}~{{item.ticketConvertEnd}}</span>
                  <button v-if="btn_staut==1" class="Button1 flex-col" @click="gotodui(index)">
                    <span class="txt5">去领取</span>
                  </button>
                  <button v-if="btn_staut==3" class="Button2 flex-col">
                    <span class="txt5">已过期</span>
                  </button>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import moment from 'dayjs'
import {getAction,postAction} from '@/utils/request.js'

export default {
  data() {
    return {
      expire_pic:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/ps17pe048ge4flhwejg77pgqog7dva8h2v7bedd528-52a0-45f6-838c-472a8febab9f',
      parting_line:
            'https://lanhu.oss-cn-beijing.aliyuncs.com/psnbo098ucswcfg6hdefh6nsophkwztnxrc445c597-4389-4c8a-b062-9b4403b10015',
      loopData0: [],
      constants: {},
      btn_staut:1,
      has_ex_staut:false,
      carNumParma:'',
      channelNoParma:''
    };
  },
  created(){

      //在页面加载时读取sessionStorage里的状态信息
   if(window.sessionStorage.getItem("carNum")&& !this.$store.state.carNum) {
   		this.$store.state.carNum = window.sessionStorage.getItem("carNum")
   }
   if(window.sessionStorage.getItem("channelNo")&& !this.$store.state.channelno) {
   		this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
   }
   //在页面刷新时将vuex里的信息保存到sessionStorage里
   window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("carNum", this.$store.state.carNum)
        window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
   })
    this.carNumParma ="?car_num="+ this.$store.state.carNum ;
    this.channelNoParma ="&channel_no="+ this.$store.state.channelno ;

    //待兑换记录
      getAction("/business/H5/queryWill"+this.carNumParma+this.channelNoParma)
       .then(response=>{
          this.loopData0 = response.data.result;
          for(var i=0;i<this.loopData0.length;i++){
            this.loopData0[i].ticketConvertStart=moment(this.loopData0[i].ticketConvertStart).format('YYYY.MM.DD');
            this.loopData0[i].ticketConvertEnd=moment(this.loopData0[i].ticketConvertEnd).format('YYYY.MM.DD');
          }
           console.log(response);
       }) ;
        this.btn_staut=1;

  },
  methods: {
    /**待兑换 */
    exchangde_will(){
      this.loopData0 =[];
       getAction("/business/H5/queryWill"+this.carNumParma+this.channelNoParma)
       .then(response=>{
          this.loopData0 = response.data.result;
          for(var i=0;i<this.loopData0.length;i++){
            this.loopData0[i].ticketConvertStart=moment(this.loopData0[i].ticketConvertStart).format('YYYY.MM.DD');
            this.loopData0[i].ticketConvertEnd=moment(this.loopData0[i].ticketConvertEnd).format('YYYY.MM.DD');
          }
           console.log(response);
       }) ;
        this.btn_staut=1;
        this.has_ex_staut=false;
    },
    /*已兑换 */
    hasexchangded(){
      this.loopData0 =[];
      /* this.$toast.loading({
          duration: 1000,
          message: '加载中...'
        }); */
       getAction("/business/H5/queryExchanged"+this.carNumParma+this.channelNoParma)
       .then(response=>{
          this.loopData0 = response.data.result;
           for(var i=0;i<this.loopData0.length;i++){
            this.loopData0[i].ticketConvertTime=moment(this.loopData0[i].ticketConvertTime).format('YYYY.MM.DD HH:MM:ss');
          }
           /* console.log(response); */
       }) ;

      this.btn_staut=2;
      this.has_ex_staut=true;
    },
    /*已超时 */
    hasdateout(){
      this.loopData0 =[];
       getAction("/business/H5/queryExpired"+this.carNumParma+this.channelNoParma)
       .then(response=>{
          this.loopData0 = response.data.result;
           for(var i=0;i<this.loopData0.length;i++){
            this.loopData0[i].ticketConvertStart=moment(this.loopData0[i].ticketConvertStart).format('YYYY.MM.DD');
            this.loopData0[i].ticketConvertEnd=moment(this.loopData0[i].ticketConvertEnd).format('YYYY.MM.DD');
          }
           console.log(response);
       }) ;
      this.btn_staut=3;
      this.has_ex_staut=false;
    },
    gotodui(a){

      const that=this
      let todui =[] ;
       todui = this.loopData0[a];
     // console.log(todui);
      var tic_no=todui.ticketNo;
      this.$store.commit('setTicketno',tic_no)
      console.log(tic_no);
      this.$router.push({name:'about2',params:{ticno:tic_no,carNum:that.$store.state.carNum}});
    },
    gotoWXmin(){
       postAction("/business/H5/getScheme?btn="+this.btn_staut).then(response=>{
          var schemeurl=response.data;
          if(schemeurl==null)
          Toast({
          message:'自动跳转失败，请重试或自行前往小程序'
        })
        else
           location.href=schemeurl
       }) ;
    }
  },
};
</script>

<style src="@/assets/css/myprize.css" />
