import {createStore} from 'vuex'

export default createStore({
    state: {
        phonenumber: '',
        activityno: '',
        ticketno: '',
        themecolor: "#00601B",
        themecolor2: "#07A438",
        channelno: '',
        carNum: '',
        citicOpenId: '',
        wxOpenId: '',
    },
    getters: {},
    mutations: {
        setPhonenumber(state, phonenumber) {
            state.phonenumber = phonenumber
        },
        setActivityno(state, activityno) {
            state.activityno = activityno
        },
        setTicketno(state, ticketno) {
            state.ticketno = ticketno
        },
        setThemeColor(state, themecolor) {
            state.themecolor = themecolor
        },
        setThemeColor2(state, themecolor2) {
            state.themecolor2 = themecolor2
        },
        setChannelNo(state, channelno) {
            state.channelno = channelno
        },
        setCarNum(state, carNum) {
            state.carNum = carNum
        },
        setCiticOpenId(state, citicOpenId) {
            state.citicOpenId = citicOpenId
        },
        setWxOpenId(state, wxOpenId) {
            state.wxOpenId = wxOpenId
        },
    },
    actions: {},
    modules: {}
})
