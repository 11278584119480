import axios from 'axios';

export const webaction = axios.create({
    timeout: 15000, // 请求超时时间
    baseURL: window._CONFIG['baseURL'],
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })


//post
export function postAction(url,parameter) {

    return webaction({
      url: url,
      method:'post' ,
      data: parameter,

    })

  }

  //post method= {post | put}
  export function httpAction(url,parameter,method) {

    return webaction({
      url: url,
      method:method ,
      data: parameter,
    })
  }

  //put
  export function putAction(url,parameter) {
    return webaction({
      url: url,
      method:'put',
      data: parameter
    })
  }

  //get
  export function getAction(url,parameter) {

    return webaction({
      url: url,
      method: 'get',
      params: parameter
    })
  }

  //deleteAction
  export function deleteAction(url,parameter) {
    return webaction({
      url: url,
      method: 'delete',
      params: parameter
    })
  }


