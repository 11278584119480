<template>

  <div class="pageactivity flex-col">
    <div class="layeractivity flex-col" :style="Acbanner">
      <div class="frame flex-col">

        <div class="pic1activity ">

        </div>
        <div class="section1activity flex-col">
          <span class="info1activity">{{ activStart }}-{{ activEnd }}</span>
        </div>

        <div class="section2activity flex-col">
          <span class="word1activity">活动说明</span>
        </div>

        <div class="section4activity flex-col">
          <span class="txt1activity">本期活动奖品</span>
        </div>

        <button class="Button1activity flex-col" @click="goToView()">
          <span class="word2activity">立即参与</span>
        </button>

      </div>
      <div class="acontent flex-col">
        <span class="content">{{ resData1.activityContent }}</span>
      </div>
      <div class="explain flex-col">
        <!-- <div class="bd1activity flex-row justify-between"> -->
        <img
            class="img1activity"
            referrerpolicy="no-referrer"
            :src="resData1.prizePic"
        />
        <!-- <div class="mod1activity flex-col justify-between">
          <span class="word3activity">{{resData1.prizeContent}}</span>
          <span class="txt2activity">价值{{resData1.prizePrice}}元</span>
        </div>
      </div> -->
      </div>
    </div>
    <temp-login ref="nana"></temp-login>
  </div>

</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import {Button} from 'vant'
import moment from 'dayjs'
import TempLogin from './TempLogin.vue';
//import { toStringJSON } from 'xe-utils/methods'
import {postAction} from '@/utils/request.js'


export default {
  name: 'Home',
  components: {
    HelloWorld,
    TempLogin,
    [Button.name]: Button
  },
  data() {
    return {
      description: '活动页面',
      resData1: [],
      prizepic: "",
      accessstatus: [],
      Acbanner: {
        backgroundImage: '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
      },
      activStart: '',
      activEnd: ''

    }
  },
  created() {

    if (window.sessionStorage.getItem("channelNo") && !this.$store.state.channelno) {
      this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
    }
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
    })
    let channo = this.$store.state.channelno;
    console.log(channo);
    // 当前活动信息
    postAction("/business/H5/curactive" + "?channel_no=" + channo)
        .then(response => {

          this.resData1 = response.data.result;
          this.activStart = moment(response.data.result.activityStart).format('YYYY.MM.DD');
          this.activEnd = moment(response.data.result.activityEnd).format('YYYY.MM.DD');
          this.Acbanner.backgroundImage = 'url(' + this.resData1.activityBanner + ')';
          console.log(response);
        });
  },
  methods: {
    goToView() {
      //TODO:调用银行授权
      //直接跳转
      this.$router.push('/bank/Dialog');
    },


  }
}
</script>

<style src="@/assets/css/activity_common.css"/>
<style src="@/assets/css/activity.css"/>