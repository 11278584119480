<template>
  <div class="duipage flex-col">
    <div class="duigroup1 flex-col">

      <div class="duiBody flex-col">

          <div class="duiouter1 flex-col"></div>
          <span class="duitxt2" id="dui">权益码：{{exView.ticketConvertNo}}</span>

          <div class="duiouter2 flex-col">
            <span class="duiword1">已领取奖品</span>
          </div>
          <div class="duiouter3 flex-col">
            <span class="duiword2">奖品领取说明</span>
          </div>
          <button class="duiButton1 flex-col" id="copyBtn"
            :data-clipboard-text="exView.ticketConvertNo" data-clipboard-action="copy" data-clipboard-target="#dui" @click="doCopy()" >
            <span class="duiinfo1">复制权益码并领取奖品</span>
          </button>

        <div class="duibd2 flex-col">
          <span class="duiinfo3">有效时间：{{stime}}～{{etime}}</span>
        </div>
        <img
          class="duipic2"
          referrerpolicy="no-referrer"
          :src="exView.prizePic"
        />
        <div class="duibd5 flex-col">
          <span class="duipara"> {{exView.prizeExplain}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HelloWorld from '@/components/HelloWorld.vue'
import {Button, Toast} from 'vant'
import Clipboard from "clipboard";
import moment from 'dayjs'
import {getAction, postAction} from '@/utils/request.js'


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    [Button.name]: Button
  },
  data(){
    return{
      exView:[],
      stime:'',
      etime:'',
    }
  },
  created(){


    //在页面加载时读取sessionStorage里的状态信息
   if(window.sessionStorage.getItem("phoneNumber")) {
   		this.$store.state.phonenumber = window.sessionStorage.getItem("phoneNumber")
   }
   if(window.sessionStorage.getItem("ticketNo")&& !this.$store.state.ticketno) {
   		this.$store.state.ticketno = window.sessionStorage.getItem("ticketNo")
   }
   //在页面刷新时将vuex里的信息保存到sessionStorage里
   window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("phoneNumber", this.$store.state.phonenumber)
        window.sessionStorage.setItem("ticketNo", this.$store.state.ticketno)
   })
   let ticket_no = this.$store.state.ticketno
    let phob=this.$store.state.phonenumber;
    console.log(phob);
    console.log(ticket_no);
      //待兑换信息
      getAction("/business/H5/exchangeView"+"?phone_number="+phob+"&ticket_no="+ticket_no).then(response=>{
         this.exView=response.data.result;
         this.stime=moment(this.exView.ticketConvertStart).format('YYYY.MM.DD');
         this.etime=moment(this.exView.ticketConvertEnd).format('YYYY.MM.DD');
           console.log(response);
       }) ;
  },
  methods: {
  //复制文本到粘贴板
      doCopy(){
        let clipboard = new Clipboard("#copyBtn");
        clipboard.on('success', function (e) {
          postAction("/business/H5/getScheme").then(response=>{
          var schemeurl=response.data;
          if(schemeurl==null)
          Toast({
          message:'自动跳转失败，请重试或自行前往小程序'
        })
        else
           location.href=schemeurl
       }) ;

      });

      clipboard.on('error', function (e) {
        console.log(e);
      });


      },

  }
}
</script>

<style src="@/assets/css/duihuan.css" />
