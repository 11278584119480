<template>
  <div class="duipage flex-col">
    <div class="duigroup1 flex-col">

      <div class="duiBody flex-col">

        <div class="duiouter1 flex-col"></div>
        <span class="duitxt2" id="dui">权益码：{{exView.ticketConvertNo}}</span>

        <div class="duiouter2 flex-col">
          <span class="duiword1">已领取奖品</span>
        </div>
        <div class="duiouter3 flex-col">
          <span class="duiword2">奖品领取说明</span>
        </div>
        <button class="duiButton1 flex-col" id="copyBtn"
                :data-clipboard-text="exView.ticketConvertNo" data-clipboard-action="copy" data-clipboard-target="#dui" @click="doCopy()" >
          <span class="duiinfo1">复制权益码并领取奖品</span>
        </button>

        <div class="duibd2 flex-col">
          <span class="duiinfo3">有效时间：{{stime}}～{{etime}}</span>
        </div>
        <img
            class="duipic2"
            referrerpolicy="no-referrer"
            :src="exView.prizePic"
        />
        <div class="duibd5 flex-col">
          <span class="duipara"> {{exView.prizeExplain}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import HelloWorld from '@/components/HelloWorld.vue'
import {Button, Toast} from 'vant'
import Clipboard from "clipboard";
import moment from 'dayjs'
import {getAction, postAction} from '@/utils/request.js'


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    [Button.name]: Button
  },
  props: {
    //用户活动权限
    exchangeParma: {
      type: String
    },
  },
  data(){
    return{
      exView:[],
      stime:'',
      etime:'',
    }
  },
  methods: {
    ready() {
      console.log(this.exchangeParma);
      //待兑换信息
      getAction("/business/H5/exchangeView" + this.exchangeParma).then(response=>{
        this.exView=response.data.result;
        this.stime=moment(this.exView.ticketConvertStart).format('YYYY.MM.DD');
        this.etime=moment(this.exView.ticketConvertEnd).format('YYYY.MM.DD');
        console.log(response);
      }) ;
    },
    doCopy(){
      let clipboard = new Clipboard("#copyBtn");
      clipboard.on('success', function (e) {
        postAction("/business/H5/getScheme").then(response=>{
          var schemeurl = response.data;
          if(schemeurl==null)
            Toast({
              message:'自动跳转失败，请重试或自行前往小程序'
            })
          else
            location.href=schemeurl
        }) ;

      });

      clipboard.on('error', function (e) {
        console.log(e);
      });
    },
  }
}
</script>

<style src="@/assets/css/duihuan.css" />
