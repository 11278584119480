<template>

   <div class="pageactivity flex-col">
    <div class="layeractivity flex-col" :style="Acbanner">
      <div class="frame flex-col">
        <!-- <img
          class="pic1activity"
          referrerpolicy="no-referrer"
          src="https://lanhu.oss-cn-beijing.aliyuncs.com/psmn5giufvpun0b8rhhaxdnh0eiv2n3uqsb8fc363a-f4f7-4297-afb3-211a58a2b92a"
        /> -->
      <div class="pic1activity ">
       <!--  <span class="artword2" >{{resData1.activityTitle}}</span>   -->
      </div>
        <div class="section1activity flex-col">
          <span class="info1activity">{{activStart}}-{{activEnd}}</span>
        </div>
      <div style="position: absolute;right: 0rem;">
        <button class="ButtonP " style="bottom:60%;" @click="gotoPrize()" >
              <span class="infoBoxP">奖<br/>品</span>
        </button>
        </div>
        <div class="section2activity flex-col">
          <span class="word1activity">活动说明</span>
        </div>
       
        <div class="section4activity flex-col">
          <span class="txt1activity">本期活动奖品</span>
        </div>

        <button v-if="accessstatus.success==true" class="Button1activity flex-col" @click="goTo()">
          <span class="word2activity">立即领取</span>
        </button> 
        <span  v-else-if="accessstatus.message=='noStart'" class="Button1activity flex-col"  >
          <span class="word2activity3">本期活动未开始</span>
        </span>
          <span  v-else-if="accessstatus.message=='over'" class="Button1activity flex-col"  >
          <span class="word2activity3">本期活动已结束</span>
        </span>
        <span  v-else-if="accessstatus.message=='noPower'" class="Button1activity flex-col"  >
          <span class="word2activity2">用户无权益</span>
        </span>
        <span  v-else-if="accessstatus.message=='powerOver'" class="Button1activity flex-col"  >
          <span class="word2activity2">权益已用完</span>
        </span>
        <span  v-else-if="accessstatus.message=='todayNoPower'" class="Button1activity flex-col"  >
          <span class="word2activity3">今日权益已用完</span>
        </span>
       
        <span v-else-if="accessstatus.message=='todayNoPrize'" class="Button1activity flex-col" >
          <span class="word2activity3">今日奖品已领完</span>
        </span>
        <span v-else-if="accessstatus.message=='noPrize'" class="Button1activity flex-col"  >
          <text class="word2activity2" >奖品已被领光</text>
        </span>
        
        <!--  <span v-else-if="accessstatus.message=='0'" class="Button1activity flex-col" >
          <span class="word2activity2">本日活动已结束</span>
        </span> -->
  

      </div>
      <div class="acontent flex-col">
        <span class="content" >{{resData1.activityContent}}</span>
      </div>
      <div class="explain flex-col">
       <!--  <div class="bd1activity flex-row justify-between"> -->
          <img
            class="img1activity"
            referrerpolicy="no-referrer"
            :src="resData1.prizePic"
          />
          <!-- <div class="mod1activity flex-col justify-between">
            <span class="word3activity">{{resData1.prizeContent}}</span>
            <span class="txt2activity">价值{{resData1.prizePrice}}元</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue'
import {Button} from 'vant'
import axios from 'axios';
 import moment from 'dayjs'
import { toStringJSON } from 'xe-utils/methods'
import {getAction,postAction} from '@/utils/request.js'


export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    [Button.name]: Button
  },
   data () {
      return {
        description: '活动页面',
        resData1:[],
        prizepic:"",
       accessstatus:[],
       Acbanner:{
        backgroundImage : '',
        backgroundRepeat : 'no-repeat',
        backgroundSize : '100% 100%',
        },
      phoneNumber:''

      }
   },
   created(){

    //在页面加载时读取sessionStorage里的状态信息
   if(window.sessionStorage.getItem("phoneNumber")&& !this.$store.state.phonenumber) {
   		this.$store.state.phonenumber = window.sessionStorage.getItem("phoneNumber")
   } 
   //在页面刷新时将vuex里的信息保存到sessionStorage里
   window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("phoneNumber", this.$store.state.phonenumber)
   })

    if(window.sessionStorage.getItem("channelNo")&& !this.$store.state.channelno) {
   		this.$store.state.channelno = window.sessionStorage.getItem("channelNo")
   } 
   //在页面刷新时将vuex里的信息保存到sessionStorage里
   window.addEventListener("beforeunload", () => {
        window.sessionStorage.setItem("channelNo", this.$store.state.channelno)
   })
   this.phoneNumber = this.$store.state.phonenumber;
    console.log(this.phoneNumber);

    
    let channo=this.$store.state.channelno;
    console.log(channo);
//当前活动信息
     postAction("/business/H5/curactive"+"?channel_no="+channo)
       .then(response=>{
         this.resData1 = response.data.result;
         this.activStart=moment(response.data.result.activityStart).format('YYYY.MM.DD');
         this.activEnd=moment(response.data.result.activityEnd).format('YYYY.MM.DD');
         this.datevalid=toStringJSON(response.data.result.validityDateJson);
         this.Acbanner.backgroundImage='url(' + this.resData1.activityBanner + ')';
           console.log(response);
           let acno=response.data.result.activityNo;
           let pho=this.phoneNumber;
          postAction("/business/H5/receivePower",{activityNo:acno,phoneNumber:pho}).then(response=>{
          this.accessstatus=response.data;
           console.log(response);
       }) ;
       }) ;

		},
  methods: {
    goTo() {
          let acno=this.resData1.activityNo;
           let pho=this.phoneNumber;
           //领取奖品
          postAction("/business/H5/receiveNew",{activityNo:acno,phoneNumber:pho}).then(response=>{
            let ticketNo=response.data.result.ticketNo;
            this.$store.commit('setTicketno',ticketNo)
           console.log(response);
            this.$router.push({name:'about',params:{ticno:ticketNo}});
       }) ;

      //跳转
      //this.$router.push('/AboutView');
      
    },
    gotoPrize()
    {
      //直接跳转
      //this.$router.push('/MyPrize');
      this.$store.commit('setPhonenumber',this.phoneNumber)
        this.$router.push({name:'myprize',params:{myphone:this.phoneNumber}});
    },
    goToJoin()
    {

    },
    
    
  }
}
</script>

<style src="@/assets/css/activity_common.css" />
<style src="@/assets/css/activity.css" />